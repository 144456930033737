.public-DraftStyleDefault-ltr {
    text-align: unset !important;
}

.scene-block {
    text-align: left;
    text-transform: uppercase;
    margin: 20px 0;
    background-color: #B6B6B6;
    color: #000 !important
}

.scene-block span {
    color: #000 !important
}

.scene-block>div>span::before {
    content: "INT./EXT. PLACE - TIME OF DAY";
    color: rgb(99, 99, 99);
    pointer-events: none;

}

.scene-block>div>span:has(> span:not(:empty))::before {

    display: none;

}



.action-block {
    text-align: left;
    text-transform: none;
    margin: 20px 0;
}

.action-block>div>span::before {
    content: "Describe what the audience sees: your setting, the characters present, and what's happening. Keep it short! Typically 3 or 4 sentences.";
    color: rgb(99, 99, 99);
    pointer-events: none;

}

.action-block>div>span:has(> span:not(:empty))::before {

    display: none;

}

.character-block {
   /* text-align: justify;*/
    text-transform: uppercase;
    /*margin: 20px 0;*/
    margin: 20px 0 -8px 0;
    margin: 8px 0px 20px 200px;

    /* Changed bottom margin to 0 */

}

.character-block>div>span::before {
    content: "CHARACTER NAME";
    color: rgb(99, 99, 99);
    pointer-events: none;

}

.character-block>div>span:has(> span:not(:empty))::before {

    display: none;

}


.dialogue-block {
    /*text-align: justify;*/
    margin: 8px 60px 20px 60px;
    /* Changed top margin to 0 */

}

.dialogue-block>div>span::before {
    content: "Write some dialog for your character to say.";
    color: rgb(99, 99, 99);
    pointer-events: none;

}

.dialogue-block>div>span:has(> span:not(:empty))::before {

    display: none;

}

.parenthetical-blockddd {
    text-align: center;
    margin: 10px 0 -8px 0;
    /* Changed bottom margin to 0 */
    font-style: italic;
}

.parenthetical-block {
    /*text-align: justify;*/
    text-transform: uppercase;
    /*margin: 20px 0;*/
    margin: 20px 0 -8px 0;
    margin: 8px 150px 20px 100px;
    font-style: italic;


    /* Changed bottom margin to 0 */

}

.parenthetical-block>div>span::before {
    content: "(parenthetical - e.g. excitedly)";
    color: rgb(99, 99, 99);
    pointer-events: none;

}

.parenthetical-block>div>span:has(> span:not(:empty))::before {

    display: none;

}



.transition-block {
    text-align: right !important;
    text-transform: uppercase;
    margin: 60px 0;
    color: blue !important;
}


.transition-block>div>span::before {
    content: "TRANSITION (E.G. CUT TO:)";
    color: rgb(99, 99, 99);
    pointer-events: none;

}

.transition-block>div>span:has(> span:not(:empty))::before {

    display: none;

}




.public-DraftStyleDefault-ltr {
    text-align: unset !important;
}

.scene-block {
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    /*margin: 10px 0;*/
    padding-left: 1.45in;
    padding-right: 1in;
    /* 1.5-inch left indent for Scene Heading */
}

.action-block {
    /*text-align: justify;*/
    padding-left: 1.45in;
    padding-right: 1.05in;

    /* 1.5-inch left indent for Action */
}

.character-block {
    /*text-align: justify;*/
    text-transform: uppercase;
    margin: 0;
    padding-left: 3.4in;
    padding-right: 3.5in;

    /* 3.5-inch left indent for Character */
}

.parenthetical-block {
   /* text-align: justify;*/
    margin: 10px 0 -8px 0;
    margin: 0;
    padding-left: 2.80in;
    padding-right: 3in;
    /* 3-inch left indent for Parenthetical */
    font-style: italic;
}

.dialogue-block {
    /*text-align: justify;*/
    /* 2.5-inch left indent and 5.5-inch right indent for Dialogue */
    margin: 0;
    padding-left: 2.5in;
    padding-right: 2.1in;
}

.transition-block {
    text-align: right !important;
    text-transform: uppercase;
    margin: 20px 0;
    margin-right: 90px;
    /* 6-inch right indent for Transition */
    color: blue !important;
}

.intercut-block,
.subheader-block,
.shot-block {
    text-align: left;
    margin: 20px 0;
    padding-left: 1.5in;
    /* 1.5-inch left indent for Intercut, Subheader, and Shot */
}

.extension-block {
    text-align: right;
    padding-right: 4in;
    /* 4-inch right indent for Extension */
    margin: 0;
}

.editor-container {
    width: 8.5in;
    /* Set width to 8.5 inches */
    margin: 0 auto 30px auto;
    /* Center align and add 30px margin-bottom */
    padding: 0;
    /* No padding, to avoid adding to width */
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: white;
    /*overflow: hidden;*/
    /* Prevent horizontal scrolling */
    /*height: 11in;*/
    height: 100%;
    min-height: 11in;
    /* Set height to 11 inches */
    box-sizing: border-box;
    margin-top: 20px;
    /* Include padding in the element's total width and height */
}

.editor-content {
    font-family: 'Courier Prime', monospace;
    padding: 0;
    /* Remove the padding */
    line-height: 1.2;
    /* Adjust line height to be more readable */
    height: 100%;
    width: 100%;
    /* Ensure the content width fits within the container */
    box-sizing: border-box;
    /* Include padding in the element's total width and height */
    overflow-wrap: break-word;
    /* Ensure long words break and do not cause overflow */
    padding-top: 1in;
    padding-bottom: 1in;

}


.dialogue-block {

    margin-bottom:20px
}